import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import DummyImage from "../../../../assets/images/client-profile-image.svg";
import ImageView from "../../../../components/Image/ImageView";
import { penOrangeIcon } from "../../../../constants/constants";
import { GetCurrentUser } from "../../../../utils/utilAuth";
import Styles from "../../Dashboard.module.scss";

const ClientDetails = ({
  setProfileModalView,
  clientProfile,
  setdashboardView,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = GetCurrentUser();

  const ClientData = [
    {
      id: Math.random(),
      key: "email",
      label: "Email",
    },
    {
      id: Math.random(),
      label: "Phone Number",
      key: "phone_no",
    },
    {
      id: Math.random(),
      label: "DOB",
      key: "date_of_birth",
    },
    {
      id: Math.random(),
      label: "Home Address",
      key: "address",
    },
    {
      id: Math.random(),
      label: "Zip Code",
      key: "zip_code",
    },
    {
      id: Math.random(),
      label: "Insurance Id",
      key: "insurance_id",
    },
    {
      id: Math.random(),
      label: "Authorized Start Date",
      key: "authorized_start_date",
    },
    {
      id: Math.random(),
      label: "Authorized End Date",
      key: "authorized_end_date",
    },
    // {
    //   id: Math.random(),
    //   label: "Units Remaining",
    //   key: "units",
    // },
    ,
  ];
  const getLetter = (name) => {
    const words = name?.split(" ").filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words?.length >= 1) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1]?.[0]?.toUpperCase() || "";
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    return extractedLetters;
  };
  const formatDate = (date) => {
    if (date) {
      const formattedDate = dayjs(date).format("MM-DD-YYYY");
      return formattedDate;
    }
  };

  const ClientDetailsData = clientProfile
    ? Object.entries(clientProfile)?.map(([key, value]) => {
        const currentLabel = ClientData?.find((data) => data?.key === key);
        if (currentLabel)
          return (
            <div
              key={key}
              className={
                Styles.ProfileContainer__LeftSidebar_Section_Information
              }
            >
              <span
                className={
                  Styles.ProfileContainer__LeftSidebar_Section_Information_label
                }
              >
                {currentLabel?.label}
              </span>
              <span
                className={`${
                  Styles.ProfileContainer__LeftSidebar_Section_Information_Info
                } ${
                  currentLabel?.label === "Email" ||
                  currentLabel?.label === "Phone Number"
                    ? "clr-orange-pri"
                    : currentLabel?.label === "Units Remaining"
                    ? "clr-grey-charchoal"
                    : "clr-dark-grey"
                }`}
              >
                {currentLabel?.label === "Home Address"
                  ? `${value} ${
                      !!clientProfile["city"]?.length
                        ? "," + clientProfile["city"]
                        : ""
                    }  ${
                      !!clientProfile["state"]?.length
                        ? "," + clientProfile["state"]
                        : ""
                    }`
                  : key === "insurance_status"
                  ? value === true
                    ? "Yes"
                    : "No"
                  : key === "date_of_birth" ||
                    key === "authorized_start_date" ||
                    key === "authorized_end_date"
                  ? formatDate(value)
                  : value}
              </span>
            </div>
          );
      })
    : [];

  const TechnicianDetails = clientProfile?.technician_details
    ? Object.entries(clientProfile?.technician_details)?.map(([key, value]) => {
        return (
          <div
            key={key}
            className={Styles.ProfileContainer__LeftSidebar_Section_Information}
          >
            <span
              className={
                Styles.ProfileContainer__LeftSidebar_Section_Information_label
              }
              style={{ textTransform: "capitalize" }}
            >
              {key}
            </span>
            {value && (
              <span
                className={`f-16 f-w-600 lh-18 clr-dark-grey ${Styles.ProfileContainer__TechnicianBadge}`}
              >
                <span
                  data-column-name="name"
                  className="rounded-avatar-letters rounded-avatar-xsm name-show"
                  alt="client-profile"
                  style={{ fontSize: "12px" }}
                >
                  {getLetter(value)}
                </span>
                {value}
              </span>
            )}
          </div>
        );
      })
    : [];

  return (
    <>
      {" "}
      <div className={Styles.ProfileContainer__LeftSidebar_Section}>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex flex-column">
              <h3 className="f-24 lh-24 f-w-700 clr-dark-grey">
                {clientProfile?.full_name}
              </h3>
              <span className="f-16 lh-20 f-w-600 clr-grey-charchoal mt-5">
                {clientProfile?.age}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="f-14 f-w-400 lh-14 clr-b-charcoal">
                Date Enrolled
              </span>

              <span className="f-16 f-w-600 lh-16 clr-b-charcoal mt-5">
                {" "}
                {clientProfile?.enrolled_date}
              </span>
            </div>
          </div>
          {clientProfile.image ? (
            <ImageView className={"rounded-avatar-lg "} src={DummyImage} />
          ) : (
            <span
              data-column-name="name"
              className="rounded-avatar-letters name-show"
              alt="client-profile"
              //   style={{ background: "purple" }}
              // src={row.image || "/src/assets/images/tkxel_logo.png"}
            >
              {getLetter(clientProfile.full_name)}
            </span>
          )}
        </div>
      </div>
      <div className={Styles.ProfileContainer__LeftSidebar_Section}>
        <section className="d-flex flex-column gap-24">
          <div className="d-flex gap-18 align-items-center justify-content-between">
            <div className="d-flex gap-20 align-items-center">
              <h4 className="f-18 lh-20 f-w-700 clr-dark-grey">
                Client Details
              </h4>
              <div
                onClick={() => {
                  setProfileModalView(true);
                }}
                className="cursor-pointer"
              >
                {penOrangeIcon}
              </div>
            </div>
            {user.role === "director" && (
              <button
                className="clr-orange-pri f-14 f-w-600 "
                style={{
                  background: "none",
                  border: "none",
                  // textDecoration: "underline",
                }}
                onClick={() => navigate(`/clients/${id}/documents`)}
              >
                View All Documents
              </button>
            )}
          </div>
          {ClientDetailsData}
        </section>
      </div>
      <div className={`${Styles.ProfileContainer__LeftSidebar_Section} pb-0`}>
        <section className="d-flex flex-column gap-10 mb-12">
          <h4 className="f-18 lh-20 f-w-700 clr-dark-grey pb-6">Technicians</h4>
          {clientProfile?.technician_details && TechnicianDetails}
        </section>
      </div>
      {user.role === "director" && (
        <div
          className={`${Styles.ProfileContainer__LeftSidebar_Section} border-0 pb-0`}
        >
          <section className="d-flex gap-10 mb-12 justify-content-between align-items-baseline">
            <h4 className="f-18 lh-20 f-w-700 clr-dark-grey pb-6">Units</h4>
            <div
              className={
                Styles.ProfileContainer__LeftSidebar_Section_Information
              }
            >
              <span
                className={`${Styles.ProfileContainer__LeftSidebar_Section_Information_Info} d-flex justify-content-between w-100 align-items-center`}
              >
                <button
                  onClick={() => {
                    setdashboardView("Units");
                    navigate(`/clients/${id}/units`);
                  }}
                  className={`w-100  height-49  f-16 f-w-600 clr-orange-pri hover-unset`}
                  style={{ border: "none", boxShadow: "unset" }}
                >
                  View All Units
                </button>
              </span>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default ClientDetails;
