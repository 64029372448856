import { React, useEffect, useState } from "react";
import Styles from "../Dashboard.module.scss";
import {
  arrowLeftPurple,
  arrowRightBreadcrumb,
} from "../../../constants/constants";
import { Breadcrumb } from "antd";
import { Button } from "react-bootstrap";
import ProfileModal from "../../../components/Modals/ProfileModal";
import { useNavigate, useParams } from "react-router-dom";
import ClientDetailChart from "./ClientDetailChart/ClientDetailChart";
import WorkedGoals from "./WorkedGoals/WorkedGoals";
import ClientDetails from "./ClientDetails/ClientDetails";
import httpClient from "../../../services/axios";
import { createNotification } from "../../../helpers/createNotifications";
import LoaderSpinner from "../../../components/general/loader/loader.spinner";
import { logout } from "../../../store/user/userSlice";
import AddUnitsModal from "../../../components/Modals/AddUnitsModal";
import { GetCurrentUser } from "../../../utils/utilAuth";

const Client = ({ setdashboardView, View }) => {
  const { id } = useParams();
  const [PrfoileModalView, setProfileModalView] = useState(false);
  const [clientProfile, setClientProfile] = useState();
  const [clientGoals, setClientGoals] = useState();
  const [chartData, setChartData] = useState();
  const [goalsOption, setGoalsOption] = useState();
  const [goalsDuration, setGoalsDuration] = useState("this_week");
  const user = GetCurrentUser();

  // const [profileUpdate, setProfileUpdate] = useState();

  useEffect(() => {
    const getQuestionData = async () => {
      try {
        if (!clientProfile) {
          const response = await httpClient.get(`api/v1/users/${id}`);
          setClientProfile(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error.response.data === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin ", "_self");
          return;
        }
        createNotification("error", error?.response?.data?.response?.error);
        console.log("Something Went wrong", error.message);
      }
    };
    if (id) getQuestionData();
  }, [clientProfile, id]);
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        if (!clientGoals) {
          const response = await httpClient.get(
            `api/v1/goals/last_worked_goals?user_id=${id}&role=client`
          );
          setClientGoals(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error.response.data === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin ", "_self");
          return;
        }
        createNotification("error", error?.response?.data?.response?.error);
        console.log("Something Went wrong", error.message);
      }
    };
    if (id) getQuestionData();
  }, [clientGoals, id]);

  useEffect(() => {
    const getQuestionData = async () => {
      try {
        const response = await httpClient.get(
          `/api/v1/progresses/client_goals_progrees?user_id=${id}&goal_reference_no=${goalsOption}&required_time=${goalsDuration}`
        );
        setChartData(response?.data?.progress);

        return response;
      } catch (error) {
        if (
          error.response.data === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin", "_self");
          return;
        }
        createNotification("error", error?.response?.data?.response?.error);
        console.log("Something Went wrong", error.message);
      }
    };
    if (goalsOption && id) {
      getQuestionData();
    }
  }, [goalsDuration, id, goalsOption]);

  const handleUpdateProfile = async (profileUpdate) => {
    try {
      if (profileUpdate) {
        const res = await httpClient.put(`api/v1/users/${id}`, {
          ...profileUpdate,
        });
        if (res.data) {
          setClientProfile(res?.data);
        }
        return true;
      }
    } catch (error) {
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };

  const navigate = useNavigate();

  const [unitsData, setUnitsData] = useState();
  const [modalLoading, setModalLoading] = useState(false);
  const [unitsModal, setUnitsModal] = useState(false);
  const handleAddunits = async () => {
    try {
      const res = await httpClient.post(
        `api/v1/units/update_or_create?user_id=${id}`,
        {
          ...unitsData,
        }
      );
      if (res?.data) {
        setModalLoading(false);
        createNotification("success", res?.data?.message);
        setUnitsModal(false);
      }
      console.log(res);
    } catch (error) {
      createNotification("error", error?.response?.data?.response.error);
      console.log(error);
      setModalLoading(false);
    }
  };
  return (
    <>
      {clientProfile && clientGoals ? (
        <section className={Styles.ProfileContainer}>
          <div className={Styles.ProfileContainer__LeftSidebar}>
            <div className={Styles.ProfileContainer__LeftSidebar_Header}>
              <div
                onClick={() => {
                  setdashboardView("Clients");
                  navigate("/clients");
                }}
                className="lm-nav-arrow"
              >
                {arrowLeftPurple}
              </div>
              <Breadcrumb
                separator={arrowRightBreadcrumb}
                items={[
                  {
                    title: "Clients",
                  },
                  {
                    title: "Client Profile  ",
                    // href: "",
                  },
                ]}
              />
            </div>

            <ClientDetails
              setProfileModalView={setProfileModalView}
              clientProfile={clientProfile}
              setdashboardView={setdashboardView}
            />
            {user.role === "director" && (
              <section className={`${Styles.ProfileContainer_Footer} mt-auto`}>
                <div className="d-flex gap-20">
                  <Button
                    onClick={() => {
                      setUnitsModal(true);
                    }}
                    className={`w-50  height-49 lm-form-btn-modalbtn lm-form-btn-modalbtn-cancel f-16 f-w-600 clr-orange-pri border-clr-orange-pri`}
                  >
                    Add Units
                  </Button>

                  <Button
                    onClick={() => {
                      setdashboardView("yourclientsingle");
                      navigate(`/clients/${id}/sessions`, {
                        state: { name: clientProfile?.full_name },
                      });
                    }}
                    className={`w-50 height-49  lm-form-btn lm-form-btn-primary f-16 f-w-600 `}
                  >
                    View Daily Sessions
                  </Button>
                </div>
              </section>
            )}
          </div>

          <div className={Styles.ProfileContainer__ClientPanel}>
            <WorkedGoals
              clientGoals={clientGoals?.goals}
              setdashboardView={setdashboardView}
              userId={id}
            />
            <ClientDetailChart
              data={chartData}
              goalsDuration={goalsDuration}
              duration={setGoalsDuration}
              option={setGoalsOption}
              userId={id}
            />
          </div>
          <ProfileModal
            show={PrfoileModalView}
            handleUpdateProfile={handleUpdateProfile}
            clientProfile={clientProfile}
            setProfileModalView={setProfileModalView}
            onHide={() => {
              setProfileModalView(false);
            }}
          />
          <AddUnitsModal
            AddingEntity="Units"
            show={unitsModal}
            onHide={() => {
              setUnitsModal(false);
            }}
            onHandleAdd={() => {
              handleAddunits();
            }}
            setCreds={setUnitsData}
            creds={unitsData}
            modalLoading={modalLoading}
            setLoadingModal={setModalLoading}
          />
        </section>
      ) : (
        <LoaderSpinner
          tip="Loading"
          size="50"
          top={"50%"}
          left={"50%"}
          color="#090834"
        />
      )}
    </>
  );
};

export default Client;
