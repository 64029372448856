import { Space, TimePicker } from "antd";
import React, { useState } from "react";

import dayjs from "dayjs";

const CustomTimePicker = ({ defaultTime, handleValue, disabled }) => {
  const [isError, setIsError] = useState("");

  const onChange = (time) => {
    if (time !== null) {
      handleValue(time);
      setIsError('');
    } else {
      setIsError('error');
    }
  };

  return (
    <div>
      <Space wrap>
        <TimePicker
          use12Hours
          format="h:mm a"
          disabled = {disabled}
          // defaultValue={dayjs(defaultTime, "HH:mm:ss")}
          onChange={onChange}
          status={isError}
          value={dayjs(defaultTime, "HH:mm:ss")}
        />
      </Space>
    </div>
  );
};

export default CustomTimePicker;
