import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import "./Modals.scss";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import DatePickerLM from "../form-controls/input/DatePicker/DatePickerLM";
import { Spin } from "antd";
import { emailRegex } from "../../helpers/utils";
import dayjs from "dayjs";

const AddClientModal = (props) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const { creds, setCreds, show, onHandleAdd, modalLoading, setLoadingModal } =
    props;

  const [emailError, setEmailError] = useState();
  useEffect(() => {
    if (
      (props.AddingEntity === "Client" || props.AddingEntity === "Director" ||  props.AddingEntity === "Admin Supporter")  &&
      creds?.first_name?.length &&
      creds?.last_name?.length &&
      creds?.email?.length &&
      creds?.enrolled_date
    ) {
      if (!emailRegex?.test(creds?.email)) {
        setEmailError(true);
        setIsDisabled(true);
        return;
      } else {
        setEmailError(false);
      }
      setIsDisabled(false);
      return;
    } else if (
      props.AddingEntity === "Technician" &&
      creds?.first_name?.length &&
      creds?.last_name?.length &&
      creds?.email?.length &&
      creds?.enrolled_date &&
      creds?.experience
    ) {
      if (creds?.email?.length && !emailRegex?.test(creds?.email)) {
        setEmailError(true);
        setIsDisabled(true);
        return;
      } else {
        setEmailError(false);
      }
      setIsDisabled(false);
    } else {
      setEmailError(false);
      setIsDisabled(true);
    }
  }, [creds]);
  useEffect(() => {
    if (!show) {
      setCreds();
      setIsDisabled(true);
    }
  }, [show]);
  return (
    <Modal
      {...props}
      size={655}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Spin size="large" spinning={modalLoading}>
        <Modal.Body>
          <Modal.Header className="border-0 p-0 pb-12" closeButton>
            <Modal.Title className="f-24 lh-25 f-w-700 clr-dark-grey">
              Add {props.AddingEntity}
            </Modal.Title>
          </Modal.Header>
          <section className="d-flex flex-column gap-32 pt-32">
            <div className="d-flex flex-column gap-20">
              <div className="d-flex gap-30">
                <TextFieldFormik
                  placeholder="Matt"
                  label="First Name"
                  wrapperClassName="w-50"
                  type="text"
                  handleChange={(e) => {
                    setCreds({ ...creds, first_name: e.target.value });
                  }}
                />
                <TextFieldFormik
                  placeholder="Sterling"
                  label="Last Name"
                  wrapperClassName="w-50"
                  type="text"
                  handleChange={(e) => {
                    setCreds({ ...creds, last_name: e.target.value });
                  }}
                />
              </div>
              <TextFieldFormik
                placeholder="Matt@gmail.com"
                label="Email"
                wrapperClassName={`w-100 ${emailError && "border-red"}`}
                type="email"
                handleChange={(e) => {
                  setCreds({ ...creds, email: e.target.value });
                }}
              />
              {props.AddingEntity === "Technician" && (
                <TextFieldFormik
                  placeholder="Type in years"
                  label="Experience"
                  wrapperClassName="w-100"
                  type="number"
                  handleChange={(e) => {
                    setCreds({ ...creds, experience: e.target.value });
                  }}
                />
              )}

              <DatePickerLM
                label={"Date of Joining"}
                onChange={(date) => {
                  const formattedDate = date
                    ? dayjs(date).format("YYYY-MM-DD")
                    : null;
                  setCreds({ ...creds, enrolled_date: `${formattedDate}` });
                }}
                // futureDate={true}
                // pastDate={false}
              ></DatePickerLM>
            </div>
            <div className="w-100 d-flex justify-content-end">
              <Button
                disabled={isDisabled}
                className={`modal-add-btn lm-form-btn-modalbtn lm-form-btn-primary f-16 f-w-600 `}
                onClick={() => {
                  setLoadingModal(true);
                  onHandleAdd();
                }}
              >
                Add {props.AddingEntity}
              </Button>
            </div>
          </section>
        </Modal.Body>
      </Spin>
    </Modal>
  );
};

export default AddClientModal;
